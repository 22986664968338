Vue.component('article-notifications', {
    props: {
        activateOnAvailability: {type: Number, default: 5},
        useAvailabilitySetting: {type: Boolean, default: true},
        stock: {type: Object, default: {}}
    },
    template: '#vue-article-notification',
    data() {
        return {
            isAvailable: true
        }
    },
    computed: Vuex.mapState({
        currentVariation: state => state.item.variation,
        mapAvailable() {
            let isAvailable;

            if(this.currentVariation.documents[0].data.filter.hasActiveChildren){
                isAvailable = true;
            } else if (this.useAvailabilitySetting) {
                isAvailable = (this.currentVariation.documents[0].data.variation.availability.id < parseInt(this.activateOnAvailability));
            } else {
                isAvailable = this.stock[this.currentVariation.documents[0].data.variation.id] > 0;
            }
            this.displayAddToCartButton(isAvailable);
            return isAvailable;
        }
    }),
    created() {
        // console.log(this.currentVariation.documents[0].data);
        if(this.currentVariation.documents[0].data.filter.hasActiveChildren){
            isAvailable = true;
        } else if (this.useAvailabilitySetting) {
            this.isAvailable = (this.currentVariation.documents[0].data.variation.availability.id < parseInt(this.activateOnAvailability));
        } else {
            this.isAvailable = this.stock[this.currentVariation.documents[0].data.variation.id] > 0;
        }
        this.displayAddToCartButton(this.isAvailable);

    },
    methods: {
        displayAddToCartButton(isAvailable) {
            this.isAvailable = isAvailable;
            if (isAvailable === false) {
                if (document.getElementsByClassName('add-to-basket-container').length > 0) {
                    document.getElementsByClassName('add-to-basket-container')[0].classList.add('hidden');
                }
            } else {
                if (document.getElementsByClassName('add-to-basket-container').length > 0) {
                    document.getElementsByClassName('add-to-basket-container')[0].classList.remove('hidden');
                }
            }
        }
    }
});
