(function ($) {
    $(document).on("submit", "#CfourNotificationForm form", function (event) {
        var form = $("#CfourNotificationForm form");
        form.find(".fa-spin").removeClass("hidden");
        event.preventDefault();
        $.ajax({
            url: form.attr("action"),
            method: "POST",
            beforeSend: function () {
                form.find("input").prop("disable", true);
                form.find("button").prop("disable", true);
            },
            data: form.serialize()
        })
            .done(
                function (data) {
                    if($(data).find('.CfourItemQuestionsResponse').hasClass('error')){
                        if(form.find('.CfourItemQuestionsResponse').length>0){
                            form.find('.CfourItemQuestionsResponse').replaceWith(data);
                        }else{
                            form.prepend(data);
                        }
                    }else{

                        form.replaceWith(data);
                    }
                }
            )
            .fail(
                function (jqXHR, textStatus) {
                    console.log("Etwas schief gelaufen: ", jqXHR, textStatus);
                }
            )
            .always(
                function (data) {
                    form.find(".fa-spin").addClass("hidden");
                }
            );
    })
})(jQuery);

$(document).ready(function(){
    try {
        var container = document.getElementById('CfourNotificationForm');
    }
    finally {
        if (container !== null && typeof container !== "undefined") {
            function variantChanged(type) {
                var orig = history[type];
                return function () {
                    var rv = orig.apply(this, arguments);
                    var e = new Event(type);
                    e.arguments = arguments;
                    window.dispatchEvent(e);
                    return rv;
                }
            }

            function initializeStock() {
                var variation = window.location.href.split('_');
                if (variation.length < 2) {
                    setVariationIdToInput();
                    variation = document.getElementById('variationId').value;
                }
                else {
                    setVariationIdToInputCeres();
                    variation = variation[variation.length - 1];
                }

                if (stocks[variation] !== null && typeof stocks[variation] !== "undefined" && stocks[variation] === 0) {
                    document.getElementById('CfourNotificationForm').classList.remove("hidden");
                    if(document.getElementsByClassName('add-to-basket-container')[0]){
                        document.getElementsByClassName('add-to-basket-container')[0].style.visibility = 'hidden';
                    }
                }
                else {
                    document.getElementById('CfourNotificationForm').classList.add("hidden");
                    if(document.getElementsByClassName('add-to-basket-container')[0]){
                        document.getElementsByClassName('add-to-basket-container')[0].style.removeProperty('visibility');
                    }

                }
            }

                history.pushState = variantChanged('pushState');
                history.replaceState = variantChanged('replaceState');

                //Initialer aufruf
                if ($('#CfourNotificationForm').hasClass('hidden')) {
                    document.getElementsByClassName('add-to-basket-container')[0].classList.remove('hidden');
                } else {
                    document.getElementsByClassName('add-to-basket-container')[0].classList.add('hidden');
                }

            if(document.getElementById('CfourNotificationData')){

                var stock = document.getElementById('CfourNotificationData').innerHTML.match(/\d+/g);

                if (stock !== null && typeof stock !== "undefined") {
                    var stocks = [];
                    for (var i = 0; i < stock.length - 1; i++) {
                        stocks[stock[i]] = parseInt(stock[i + 1]);
                        i++;
                    }
                    window.addEventListener('replaceState', function () {
                        initializeStock();
                    });
                    initializeStock();
                }
            }
        }
    }
});

function setVariationIdToInput(){
    var variationInput = document.getElementById('variationId');

    var tabContent = document.getElementsByClassName('tab-content')[0];
    var nodes;

    for(var i=0;i<tabContent.childNodes.length;i++){
        nodes = tabContent.childNodes[i];
        if(nodes.nodeName === "DIV"){
            var variation = nodes.id.split('details-');
            variationInput.value = variation[variation.length - 1];
            break;
        }
    }
}

function setVariationIdToInputCeres(){
    var variationInput = document.getElementById('variationId');
    var variation = window.location.href.split('_');
    variationInput.value = variation[variation.length - 1];
}